<template>
  <div class="chart-container">
    <div class="chart-title">
      <span>Status</span>
      <dots-horizontal :size="24" fillColor="#bfc5d2" />
    </div>
    <apexchart
      class="apexcharts-chart"
      v-if="series.length > 0"
      width="100%"
      height="290px"
      type="donut"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import DotsHorizontal from "vue-material-design-icons/DotsHorizontal";
import VueApexCharts from "vue-apexcharts";
import _ from "lodash";

export default {
  name: "dashboard-charts",
  components: {
    apexchart: VueApexCharts,
    DotsHorizontal
  },
  mounted() {
    let totalCount = this.$store.state.chartDevicesCount || 0;
    this.$watch("chartDevicesCount", newCount => {
      totalCount = newCount;
    });
    this.$watch(
      "chartDevicesData",
      newDevices => {
        let temporaryDevices = [
          {
            name: "Unknown",
            value: 0
          }
        ];
        let labels = [];
        let series = [];
        let distro;

        for (let i = 0; i < newDevices.length; i++) {
          if (_.get(newDevices[i], "sysInfo.software.distro", null)) {
            distro = newDevices[i].sysInfo.software.distro;
          }
          if (distro) {
            let found = false;
            temporaryDevices.forEach(distribution => {
              if (distribution.name === distro) {
                distribution.value = distribution.value + 1;
                found = true;
              }
            });
            if (!found) {
              temporaryDevices.push({
                name: distro,
                value: 1
              });
            }
          } else {
            temporaryDevices.forEach(distribution => {
              if (distribution.name === "Unknown") {
                distribution.value = distribution.value + 1;
              }
            });
          }
        }
        temporaryDevices.sort(function(a, b) {
          return b.value - a.value;
        });
        temporaryDevices.forEach(distribution => {
          labels.push(distribution.name + " (" + distribution.value + ")");
          series.push(distribution.value);
        });
        let { seriesMerged, labelsMerged } = this.mergeUseless(
          labels,
          series,
          newDevices.length
        );

        this.series = seriesMerged;
        this.chartOptions = { ...this.chartOptions, labels: labelsMerged };
      },
      { immediate: true }
    );
    this.chartOptions = {
      ...this.chartOptions,
      plotOptions: {
        pie: {
          donut: {
            size: "60%",
            labels: {
              show: true,
              name: {
                show: false,
                fontSize: "90%",

                fontFamily: "Rubik, Arial, sans-serif",
                color: undefined,
                offsetY: 16
              },
              value: {
                show: true,
                fontSize: "20px",

                fontFamily: "Rubik, Arial, sans-serif",
                color: undefined,
                offsetY: 0,
                formatter: function(val) {
                  return ((val / totalCount) * 100).toFixed(2) + "%";
                }
              },
              total: {
                show: true,
                label: "Total",
                color: "#373d3f",
                formatter: function() {
                  return totalCount;
                }
              }
            }
          }
        }
      }
    };
  },
  computed: {
    // chartRawInfo () {

    // },
    chartDevicesCount() {
      return this.$store.state.chartDevicesCount;
    },
    chartDevicesData() {
      this.$log(this.$store.state.chartDevicesData);
      return this.$store.state.chartDevicesData;
    }
  },
  methods: {
    // updateChart: (newDevices) => {

    // },
    mergeUseless(labels, series, length) {
      let seriesMerged = [];
      let labelsMerged = [];

      for (let i = 0; i < labels.length; i++) {
        if ((series[i] / length) * 100 < 1) {
          const othersIndex = labelsMerged.indexOf("Others");
          if (othersIndex !== -1) {
            seriesMerged[othersIndex] += series[i];
          } else {
            labelsMerged.push("Others");
            seriesMerged[labelsMerged.length - 1] = series[i];
          }
        } else {
          const labelIndex = labelsMerged.indexOf(labels[i]);
          if (labelIndex !== -1) {
            seriesMerged[labelIndex] += series[i];
          } else {
            labelsMerged.push(labels[i]);
            seriesMerged[labelsMerged.length - 1] = series[i];
          }
        }
      }
      return { labelsMerged, seriesMerged };
    }
  },
  data() {
    return {
      series: [],
      chartOptions: {
        theme: {
          palette: "palette7"
        },
        legend: {
          show: true,
          position: "right",
          horizontalAlign: "center",
          offsetY: 24,
          fontFamily: "Rubik",
          fontSize: "15px"
        },
        labels: [],
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          pie: {}
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.chart-container {
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  height: 330px;
  background-color: #ffffff;
  border-radius: 1px;
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
  .apexcharts-chart {
  }
  .chart-title {
    padding-top: 32px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    span {
      font-size: 13px;
      font-family: Rubik;
      letter-spacing: 1.2px;
      color: #8798ad;
      text-transform: uppercase;
    }
  }
}
</style>
