<template>
  <div class="background-gradiented" v-bind:class="{'filtersopened': this.$store.state.filtersOpened}">
    <div>
      <img src="../../assets/artwork.png" />
    </div>
    <div class="text">
      <h1>Nothing to see here...</h1>
      <h3>Try to select some filters</h3>
      <arrow-left class="arrow" :size="24" fillColor="302c48" />
    </div>
  </div>
</template>

<script>
import ArrowLeft from 'vue-material-design-icons/ArrowLeft.vue'
export default {
  name: 'empty-results',
  components: {
    ArrowLeft
  }
}
</script>

<style lang="scss" scoped>
.background-gradiented {
  width: calc(100% - (81px + 55px));
  height: 65%;
  position: absolute;
  left: calc(81px + 55px);
  top: 80px;
  background-image: linear-gradient(210deg, rgba(255, 255, 255, 0), #e6eaee);
  transition: left 0.3s ease;
  display: grid;
  grid-template-columns: 1.6fr 1.4fr;
  img {
    position: absolute;
    bottom: -180px;
    margin-left: 24px;
    left: 0;
    object-fit: contain;
  }
  .text {
    display: flex;
    justify-content: center;
    width: 467px;
    flex-direction: column;
    margin-top: 24px;
    h1 {
      font-weight: bold;
      font-size: 80px;
      color: #302c48;
      font-family: Didot
    }
    h3 {
      font-family: Rubik;
      font-weight: 300;
      margin-top: 20px;
      color: #302c48;
      font-size: 25px;
    }
    .arrow {
      margin-top: 20px;
    }
  }
}
.filtersopened {
  width: calc(100% - (260px + 81px)) !important;
  left: calc(260px + 81px);
  margin-left: 0px !important;
  margin-right: 0px !important;
}
</style>
