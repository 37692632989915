<template>
  <div>
    <loading v-if="loaderRunning" :msg="loadingMessage"/>
    <div class="tools-container" v-bind:class="{'filtersopened': this.$store.state.filtersOpened}">
      <ul>
        <li v-on:click="setDashboardTab('dashboard')" v-bind:class="{ active: this.$store.state.dashboardTab === 'dashboard' }">Dashboard</li>
        <li v-on:click="setDashboardTab('table')" v-bind:class="{ active: this.$store.state.dashboardTab === 'table' }">Table</li>
      </ul>
      <div class="right-buttons">
        <button v-on:click="generateCSV()" v-bind:class="(this.$store.state.chartDevicesCount > 0)? 'csv-available' : 'csv-unavailable'"> <img src="../../assets/import_export-24px 1.svg" class="import-icon" :size="24"/>Export CSV</button>
        <button v-on:click="toggleQuery()" class="query-available"><pulse class="pulse-icon" :size="24" fillColor="#ffffff"/> Query</button>
      </div>
    </div>
    <div class="query-box" v-if="queryVisible">
      <div class="query-container">
        <textarea v-model="this.$store.state.query"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Pulse from 'vue-material-design-icons/Pulse.vue'
import Loading from "../reusable/Loading";
const { Parser } = require('json2csv');

export default {
  name: 'dashboard-tools',
  components: {
    Pulse,
    loading: Loading,
  },
  data() {
    return {
      queryVisible: false,
      loaderRunning: false,
      loadingMessage: "",

    }
  },
  methods: {
    toggleQuery () {
      this.queryVisible = !this.queryVisible;
    },
    generateCSV() {
      if(this.$store.state.chartDevicesCount > 0){
      this.loadingMessage = "Generating CSV";
      this.loaderRunning = true;
      return new Promise((resolve, reject) => {
        const requestBody = {
          query: this.$store.state.query
        };
          axios
            .post(
              `${process.env.VUE_APP_API_ROOT}/devices/getAll`,
              requestBody,
              {
                headers: {
                  Authorization: `Bearer ${this.$store.state.token}`
                }
              }
            )
            .then(response => {
              let filteredData = [];
              let date_ob = new Date();
              for (var device in response.data.data){
                let singleResult = {}

                try{
                  singleResult["uuid"]=response.data.data[device].uuid;
                } catch(exception){
                  singleResult["uuid"]="";
                }
                try{
                  singleResult["name"]=response.data.data[device].name;
                } catch(exception){
                  singleResult["name"]="";
                }
                try{
                  singleResult["companyName"]=response.data.data[device].companyName;
                } catch(exception){
                  singleResult["companyName"]="";
                }
                try {
                  singleResult["type"] = response.data.data[device].type;
                } catch(exception) {
                  singleResult["type"] = "";
                }
                try{
                  singleResult["betshopName"]=response.data.data[device].betshopName;
                } catch(exception){
                  singleResult["betshopName"]="";
                }
                try{
                  singleResult["distro"]=response.data.data[device].sysInfo.software.distro;
                } catch(exception){
                  singleResult["distro"]="";
                }
                try{
                  singleResult["dateInstalled"]=response.data.data[device].sysInfo.software.dateInstalled;
                } catch(exception){
                  singleResult["dateInstalled"]="";
                }
                try{
                  singleResult["localIp"]=response.data.data[device].sysInfo.hardware.localIp;
                } catch(exception){
                  singleResult["localIp"]="";
                }
                try{
                  singleResult["online"]=response.data.data[device].online;
                } catch(exception){
                  singleResult["online"]="";
                }
                try{
                  singleResult["isActive"]=response.data.data[device].isActive;
                } catch(exception){
                  singleResult["isActive"]="";
                }
                try{
                  singleResult["isInstalled"]=response.data.data[device].isInstalled;
                } catch(exception){
                  singleResult["isInstalled"]="";
                }
                singleResult["reportDate"] = date_ob.toUTCString();
                filteredData.push(singleResult);
              }

              const fields = [{
                label: 'Device UUID',
                value: 'uuid'
              },{
                label: 'Device Name',
                value: 'name'
              },{
                label: 'Company',
                value: 'companyName'
              },{
                label: 'Betshop',
                value: 'betshopName'
              },
              {
                label: 'Type',
                value: 'type'
              },
              {
                label: 'Distro',
                value: 'distro'
              },{
                label: 'Installation Date',
                value: 'dateInstalled'
              },{
                label: 'Local IP',
                value: 'localIp'
              },{
                label: 'Online',
                value: 'online'
              },{
                label: 'Active Status',
                value: 'isActive'
              },{
                label: 'Installation Status',
                value: 'isInstalled'
              },{
                label: 'Report Date',
                value: 'reportDate'
              }];
              const json2csvParser = new Parser({fields});
              const csv = json2csvParser.parse(filteredData)

              var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
              var link = document.createElement('a');
              var url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', 'csv_'+date_ob.getTime()+'.csv');
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              this.loaderRunning = false;
              this.loadingMessage = "";

              resolve(true);
            })
            .catch(err => {
              this.$log(err);
              reject(err);
            });
      });
    }},
    setDashboardTab (tab) {
      this.$store.commit('updateDashboardTab', tab)
    }
  }
}
</script>

<style lang="scss" scoped>
  .query-box {
    background-color: #ffffff;
    position: absolute;
    right: 24px;
    top: 136px;
    width: 25%;
    height: 28%;
    z-index: 100;
    border-radius: 1px;
    box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
    .query-container {
      background-color: #f4f6fc;
      margin: 24px;
      height: calc(100% - (24px * 2));
      textarea {
        font-family: Rubik;
        outline: none;
        color: #69707f;
        font-size: 15px;
        border: 0;
        width: 100%;
        resize: none;
        height: 100%;
        box-sizing: border-box;
        background-color: transparent;
        padding: 24px 64px;
      }
    }

  }
  .tools-container {
    background: #f4f6fc;
    z-index: 3;
    height: 46px;
    width: calc(100% - (55px + 87px));
    left: calc(55px + 81px);
    position: absolute;
    top: 80px;
    padding-left: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: 140px;
      height: 32px;
      border: 0;
      border-radius: 4px;
      background-color: #bfc5d2;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 15px;
      color: #ffffff;
      font-family: Rubik;
      padding: 0 16px;
      outline: none;
      .pulse-icon {
        padding-top: 4px;
        margin-right: 16px;
      }
      .import-icon {
        padding-top: 4px;
        margin-right: 16px;
      }
    }
    .query-available {
      background: #2e5bff;
      cursor: pointer;
      transition: background 0.3s ease;
    }
    .query-available:hover {
      background: #3f6cff;
    }
    .csv-unavailable {
      width: 160px;
      margin-right: 16px;
      background: #aaaaaa;
      cursor: pointer;
      transition: background 0.3s ease;
    }
    .csv-available {
      margin-right: 16px;
      width: 160px;
      background: #33ac2e;
      cursor: pointer;
      transition: background 0.3s ease;
    }
    .csv-available:hover {
      background: #32ac2edf;
    }
    ul {
      li {
        margin-right: 16px;
        display: inline-block;
        font-size: 13px;
        font-family: Rubik;
        font-weight: normal;
        color: #8798ad;
        text-transform: uppercase;
        cursor: pointer;
        transition: border-bottom 0.3s ease;
      }
      li:hover {
        padding-bottom: 8px;
        border-bottom: 3px solid rgba(0, 141, 208, 0.3)
      }
      .active {
        padding-bottom: 8px;
        border-bottom: 3px solid #008dd0;
      }
    }
  }
  .filtersopened {
    width: calc(100% - (260px + 81px));
    left: calc(260px + 81px);
  }
  .right-buttons {
    display: flex;
  }
</style>
