<template>
  <div>
    <div class="filters-container" :class="{closed: !this.$store.state.filtersOpened}">

      <div v-if="this.$store.state.filtersOpened">
        <div class="toggle-container">
          <span class="title">Filters</span>
          <chevron-left class="clickable" v-on:click="closeFilters" :size="24" fillColor="#bfc5d2" />
        </div>
        <div class="filters">
          <span class="title" style="margin: 16px 0; display: block">Metadata</span>
          <div class="toggle-container">
            <span class="toggle-label">Active <span v-on:click="removeSwitch('isActive')" class="clear-link clickable">(clear)</span></span>
            <toggle-switch v-if="filters.toggles.isActive" v-on:click="toggleSwitch('isActive')" :size="36" fillColor="#33ac2e" />
            <toggle-switch-off v-else :size="36" v-on:click="toggleSwitch('isActive')" fillColor="rgba(135,152,173,0.4)" />
          </div>
          <div class="toggle-container">
            <span class="toggle-label">Online <span v-on:click="removeSwitch('online')" class="clear-link clickable">(clear)</span></span>
            <toggle-switch v-if="filters.toggles.online" v-on:click="toggleSwitch('online')" :size="36" fillColor="#33ac2e" />
            <toggle-switch-off v-else v-on:click="toggleSwitch('online')" :size="36" fillColor="rgba(135,152,173,0.4)" />
          </div>
          <div class="toggle-container">
            <span class="toggle-label">Installed <span v-on:click="removeSwitch('isInstalled')" class="clear-link clickable">(clear)</span></span>
            <toggle-switch  v-if="filters.toggles.isInstalled" v-on:click="toggleSwitch('isInstalled')" :size="36" fillColor="#33ac2e" />
            <toggle-switch-off v-else v-on:click="toggleSwitch('isInstalled')" :size="36" fillColor="rgba(135,152,173,0.4)" />
          </div>
          <div class="selectable-filter-container">
            <label>DC <span v-on:click="removeDatacenter()" class="clear-link clickable">(clear)</span></label>
            <div class="select-container">
              <select v-model="filters.dropdowns.datacenter" v-on:change="updateDatacenter()">
                <option value="dc1_london">London</option>
                <option value="dc4_ro">Romania</option>
                <option value="dc6_ro2">Romania (2)</option>
                <option value="dc2_serbia">Serbia</option>
                <option value="dc8_de">Germany</option>
              </select>
              <menu-swap class="menu-swap-icon" :size="24" fillColor="#8798ad" />
            </div>
          </div>
          <div class="selectable-filter-container">
            <label>Company <span v-on:click="removeCompany()" class="clear-link clickable">(clear)</span></label>
            <div class="select-container">
              <multiselect v-model="filters.dropdowns.company" @input="callUpdateBetshops" placeholder="Choose a company" :allowEmpty="false" :options="companies" label="displayId">

              </multiselect>
<!--              <select v-model="filters.dropdowns.company" v-on:change="callUpdateBetshops()">-->
<!--                <option value="null" disabled>Choose a company</option>-->
<!--                <option v-bind:key="index" v-for="(item, index) in this.$store.state.companies"-->
<!--                    :value="index">{{item.displayId}}</option>-->
<!--              </select>-->

<!--              <menu-swap class="menu-swap-icon" :size="24" fillColor="#8798ad" />-->
            </div>
          </div>
          <div class="input-filter-container datepicker">
            <label>Last online after</label>
            <datepicker placeholder="Select date" calendar-class="calendar" input-class="input-datepicker" v-model="filters.inputs.lastOnline" v-on:input="updateQueryInputs('lastOnline')">

            </datepicker>
          </div>
          <!-- <div class="input-filter-container">
            <label>Betshop UUID</label>
            <input v-model="filters.inputs.cpu" v-on:keyup="updateQueryInputs('betshopUuid')" type="text" placeholder="Enter UUID">
          </div>
          <div class="input-filter-container">
            <label>Device UUID</label>
            <input v-model="filters.inputs.cpu" v-on:keyup="updateQueryInputs('deviceUuid')" type="text" placeholder="Enter UUID">
          </div> -->
          <span class="title" style="margin: 16px 0; display: block">Hardware</span>

          <div class="input-filter-container">
            <label>CPU</label>
            <input v-model="filters.inputs.cpu" v-on:keyup="updateQueryInputs('cpu')" type="text" placeholder="Enter CPU">
          </div>
          <div class="input-filter-container">
            <label>GPU</label>
            <input v-model="filters.inputs.gpu" v-on:keyup="updateQueryInputs('gpu')" type="text" placeholder="Enter GPU">
          </div>
          <span class="title" style="margin: 16px 0; display: block">Software</span>

          <div class="input-filter-container">
            <label>Distro</label>
            <input v-model="filters.inputs.distro" v-on:keyup="updateQueryInputs('distro')" type="text" placeholder="Enter Distro">
          </div>
          <div class="input-filter-container">
            <label>Application Name</label>
            <input type="text" placeholder="Enter application name">
          </div>
        </div>
        <div class="filter-search">
          <button v-on:click="() => { loadData() }"><check :size="16" fillColor="#ffffff" /> Get Results</button>
        </div>
      </div>


      <div v-else>
        <div class="toggle-container container-closed">
          <chevron-right class="clickable" v-on:click="openFilters" size="24" fillColor="#008dd0" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { bus } from '../../main'
import Datepicker from 'vuejs-datepicker';
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue'
import ChevronLeft from 'vue-material-design-icons/ChevronLeft.vue'
import ToggleSwitchOff from 'vue-material-design-icons/ToggleSwitchOff.vue'
import ToggleSwitch from 'vue-material-design-icons/ToggleSwitch.vue'
import MenuSwap from 'vue-material-design-icons/MenuSwap.vue'
import Check from 'vue-material-design-icons/Check.vue'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Datepicker,
    ChevronRight,
    ChevronLeft,
    ToggleSwitchOff,
    ToggleSwitch,
    MenuSwap,
    Check,
    Multiselect
  },
  data() {
    return {
      filters: {
        dropdowns: {
          company: null,
          datacenter: ''
        },
        inputs: {
          cpu: '',
          gpu: '',
          distro: '',
          appName: '',
          lastOnline: ''
        },
        toggles: {
          isActive: false,
          isInstalled: false,
          online: false,
        }
      }
    }
  },
  props: ['loadQuery'],
  computed: {
    companies() {
      if (this.$store.state.companies) {
        return this.$store.state.companies;
      } else {
        return []
      }
    }
  },
  methods: {
    loadData() {
      this.loadQuery()
    },

    updateQueryInputs(filterType) {
      let object = JSON.parse(this.$store.state.query);
        switch(filterType) {
          case 'cpu':
            if (this.filters.inputs.cpu) {
              object["sysInfo.hardware.cpu.version"] = this.filters.inputs.cpu;
            } else {
              delete object["sysInfo.hardware.cpu.version"];
            }
            break;
          case 'gpu':
            if (this.filters.inputs.gpu) {
              object["sysInfo.hardware.vga"] = this.filters.inputs.gpu;
            } else {
              delete object["sysInfo.hardware.vga"];
            }
            break;
          case 'distro':
            if (this.filters.inputs.distro) {
              object["sysInfo.software.distro"] = this.filters.inputs.distro;
            } else {
              delete object["sysInfo.software.distro"];
            }
            break;
          case 'lastOnline':
            if (this.filters.inputs.lastOnline) {
              object["onlineAfter"] = `${new Date(this.filters.inputs.lastOnline).getTime()}`
            } else {
              delete object["onlineAfter"];
            }
            break;
        }
        this.$store.commit('updateQuery', JSON.stringify(object, null, 2));
    },
    updateDatacenter() {
      let object = JSON.parse(this.$store.state.query);
      object["companyDc"] = this.filters.dropdowns.datacenter;
      this.$store.commit('updateQuery', JSON.stringify(object, null, 2));
    },
    removeDatacenter() {
      let object = JSON.parse(this.$store.state.query);
      delete object["companyDc"];
      this.filters.dropdowns.datacenter = "";
      this.$store.commit('updateQuery', JSON.stringify(object, null, 2));
    },
    removeCompany() {
      let object = JSON.parse(this.$store.state.query);
      delete object["betshopUuid"];
      this.filters.dropdowns.company = "";
      this.$store.commit('updateQuery', JSON.stringify(object, null, 2));
    },
    callUpdateBetshops(e) {
      bus.$emit('updateBetshops', e)
    },
    openFilters () {
      this.$store.commit('updateFiltersOpened', true);
    },
    closeFilters () {
      this.$store.commit('updateFiltersOpened', false);
    },
    toggleSwitch (toggle) {
      this.filters.toggles[toggle] = !this.filters.toggles[toggle]
      let object = JSON.parse(this.$store.state.query);
      object[toggle] = this.filters.toggles[toggle];
      this.$store.commit('updateQuery', JSON.stringify(object, null, 2));
    },
    removeSwitch (toggle) {
      let object = JSON.parse(this.$store.state.query);
      delete object[toggle];
      this.$store.commit('updateQuery', JSON.stringify(object, null, 2));
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect {
  font-family: Rubik;
}
  .multiselect__placeholder {
    margin-bottom: 0 !important;
  }
  .multiselect__tags {
    display: flex;
    align-items: center;
    padding: 0px 40px 0px 16px;
    background-color: rgba(224, 231, 255, 0.2);
  }
.multiselect__option--highlight {
  background: #2e5bff;
}
.multiselect__option--highlight:after {
  background: #2e5bff;
}
.multiselect__single,.multiselect__input {
  background-color: transparent;
  margin-bottom: 0;
}
.calendar {
  font-family: Rubik;
}
.input-datepicker {
  box-sizing: border-box;
  width: 100%;
  height: 38px;
  border-radius: 5px;
  border: 0;
  border: 1px solid #e0e7ff;
  padding: 16px;
  font-family: Rubik;
  outline: none;
  color: #333333;
  font-size: 15px;
  background-color: rgba(224, 231, 255, 0.2);
}

.input-datepicker::-moz-placeholder {
  font-size: 15px;
  font-family: Rubik;
  color: #b0bac9;
}

.input-datepicker::-webkit-placeholder {
  font-size: 15px;
  font-family: Rubik;
  color: #b0bac9;
}

</style>
<style lang="scss" scoped>

@-moz-document url-prefix() {
  select {
    padding: 0px 16px 0px 16px !important;
  }
}
.filter-search {
  margin-top: 16px;
  margin-bottom: 16px;
  button {
    margin-top: 16px;
    width: 100%;
    border: 0;
    color: #ffffff;
    font-family: Rubik;
    font-size: 15px;
    font-weight: 400;
    height: 48px;
    border-radius: 4px;
    background-color: #33ac2e;
    transition: all 0.25s ease;
    outline: none;
    cursor: pointer;
  }
  button:hover {
    background-color: #44bd2f;

  }
}
.menu-swap-icon {
  padding-top: 4px;
}
.filters-container {
  width: 260px;
  height: calc(100vh - 80px);
  background-color: #ffffff;
  padding: 16px;
  transition: width 0.3s ease;
  .filters {
    // margin-top: 32px;
    // padding: 0 8px;
    overflow-y: scroll;
    height: calc(100vh - 240px);
  }
  .filters::-webkit-scrollbar {
    scroll-margin-left: 8px !important;
    width: 4px;
  }
  .filters::-webkit-scrollbar-thumb {
    background: rgba(0, 141, 208, 0.35);
  }
  .title {
    font-family: Rubik;
    font-size: 13px;
    font-weight: normal;
    color: #8798ad;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }
  .toggle-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    .toggle-label {
      font-family: Rubik;
      font-size: 12px;
      font-weight: 400;
      color: #b0bac9;
      letter-spacing: 1.1px;
      text-transform: uppercase;
      .clear-link {
        font-size: 10px;
        color: #d1d9e6
      }
      .clear-link:hover {
        color: rgb(0, 141, 208);
      }
    }
    .clickable {
      cursor: pointer;
    }
  }
  .selectable-filter-container {
    padding-top: 12px;
    padding-bottom: 12px;
    label {
      margin-bottom: 8px;
      text-transform: uppercase;
      display: block;
      font-family: Rubik;
      letter-spacing: 1.1px;
      color: #b0bac9;
      font-weight: 400;
      font-size: 12px;
      .clear-link {
        font-size: 10px;
        color: #d1d9e6
      }
      .clear-link:hover {
        color: rgb(0, 141, 208);
      }
    }
    .clickable {
      cursor: pointer;
    }
    .select-container {
      background-color: rgba(224, 231, 255, 0.2);
      display: flex;
      align-items: center;
      justify-content: space-between;
      select {
        width: 90%;
        border: 0;
        height: 38px;
        padding: 0 16px;
        font-size: 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-family: Rubik;
        outline: none;
        color: #333333;
        background: transparent;
      }
      select:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 #000;
      }
    }
  }

  .input-filter-container {
    padding-top: 12px;
    padding-bottom: 12px;
    input {
      box-sizing: border-box;
      width: 100%;
      height: 38px;
      border-radius: 5px;
      border: 0;
      border: 1px solid #e0e7ff;
      padding: 16px;
      font-family: Rubik;
      outline: none;
      color: #333333;
      font-size: 15px;
      background-color: rgba(224, 231, 255, 0.2);
    }
    input::-moz-placeholder {
      font-size: 15px;
      font-family: Rubik;
      color: #b0bac9;

    }
    label {
      margin-bottom: 8px;
      text-transform: uppercase;
      display: block;
      font-family: Rubik;
      letter-spacing: 1.1px;
      color: #b0bac9;
      font-weight: 400;
      font-size: 12px;
      .clear-link {
        font-size: 10px;
        color: #d1d9e6
      }
      .clear-link:hover {
        color: rgb(0, 141, 208);
      }
    }
    .clickable {
      cursor: pointer;
    }
  }
  .container-closed {
    justify-content: center;
  }
}
.closed {
  width: 55px;
}
</style>
