<template>
  <div class="modal-body">
    <div class="modal-container">
      <pulse-loader :size="'64px'" :color="'#008dd0'" :loading="true" />
      <h1>{{ msg }}</h1>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/RingLoader.vue'
export default {
  name: 'loading',
  props: ['msg'],
  components: {
    PulseLoader
  }
}
</script>

<style lang="scss" scoped>
.modal-body {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-container {
    width: 35%;
    height: 35%;
    background-color: #ffffff;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
      font-family: Rubik;
      margin-top: 64px;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>
