<template>
  <div class="device-row">
    <div class="specs-container">
      <div class="spec">
        <label>CPU:</label>
        <span>{{rowData.sysInfo.hardware.cpu.version}}</span>
      </div>
      <div class="spec">
        <label>GPU:</label>
        <span>{{rowData.sysInfo.hardware.vga}}</span>
      </div>
      <div class="spec">
        <label>HDD:</label>
        <span>{{`${rowData.sysInfo.hardware.hdd.model} (${rowData.sysInfo.hardware.hdd.size} GB)`}}</span>
      </div>
    </div>
    <div class="displays-container">
      <div class="display-container" v-for="(display, index) in rowData.config.displays" v-bind:key="index" >
        <label>Display info</label>
        <div class="display-name-container">
          <img alt="monitor-icon" src="../../assets/monitor.png" />
          <div class="display-name-info">
            <label>Display name</label>
            <span>{{display.displayName ? display.displayName : "Unknown"}}</span>
          </div>
        </div>
        <div class="display-output">
          <label>Display output</label>
          <span>{{display.displayOutput}}</span>
        </div>
        <div class="display-applications">
          <label>Applications</label>
          <span v-for="(app, index) in display.apps" v-bind:key="index">{{app.applicationName}}</span>
        </div>
        <div class="display-output">
          <label>Display resolution</label>
          <span>{{display.currentResolution}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dashboard-table-details",
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
.device-row {
  padding-left: 24px;
  padding-right: 24px;
}
.specs-container {
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: solid 2px #2e5bff;
  .spec {
    padding: 14px;
    label {
      font-family: Rubik;
      font-size: 12px;
      font-weight: 500;
      color: #b0bac9;
      display: block;
      margin-bottom: 8px;
    }
    span {
      font-family: Rubik;
      font-size: 13px;
      color: #2e384d;
      font-weight: normal;
    }
  }
}
.displays-container {
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
}
.display-container {
  border-radius: 1px;
  box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
  padding: 24px;
  margin-left: 16px;
  margin-right: 16px;
  overflow-x: append;
  &:first-child {
    margin-left: 0px;
  }
  &:last-child {
    margin-right: 0px;
  }
  label {
    font-size: 13px;
    font-family: Rubik;
    color: #8798ad;
    text-transform: uppercase;
  }
  .display-name-container {
    margin-top: 32px;
    display: flex;
    align-items: center;
    img {
      width: 48px;
      height: 48px;
    }
    .display-name-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 12px;
      label {
        font-weight: 500;
        font-size: 12px;
      }
      span {
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 15px;
        font-family: Rubik;
        color: #2e384d;
      }
    }
  }
  .display-output {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    label {
        font-weight: 500;
        font-size: 12px;
      }
    span {
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 15px;
      font-family: Rubik;
      color: #2e384d;
    }
  }
  .display-applications {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    label {
        font-weight: 500;
        font-size: 12px;
      }
    span {
      margin-top: 8px;
      display: block;
      margin-bottom: 8px;
      font-size: 15px;
      font-family: Rubik;
      color: #2e384d;
    }
  }
}
  
//   background: url("../../assets/monitor.png") center
//     no-repeat;
//   background-size: contain;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   width: 300px;
//   height: 200px;
//   margin-right: 16px;
//   padding: 15px;
</style>
