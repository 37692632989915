<template>
  <div class="dashboard-body">
    <loading v-if="loaderRunning" :msg="loadingMessage"/>
    <div class="dashboard-container">
      <filters :load-query="() => { loadQuery() }"/>
      <dashboard-tools/>
      <div v-if="this.$store.state.dashboardTab === 'table'">
        <div
          class="data-container table"
          v-if="this.$store.state.tableDevicesData.length > 0"
          v-bind:class="{filtersopened: this.$store.state.filtersOpened}"
        >
          <dashboard-table :load-page="(page) => { loadTableData(page) }"/>
        </div>
        <empty-results v-else/>
      </div>

      <div v-else>
        <div
          class="data-container charts"
          v-if="this.$store.state.chartDevicesData.length > 0"
          v-bind:class="{filtersopened: this.$store.state.filtersOpened}"
        >
          <dashboard-charts/>
        </div>
        <empty-results v-else/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { bus } from "../../main";
import Filters from "./Filters";
import Loading from "../reusable/Loading";
import EmptyResults from "./EmptyResults";
import DashboardTools from "./DashboardTools";
import DashboardCharts from "./DashboardCharts";
import DashboardTable from "./DashboardTable";
export default {
  name: "Dashboard",
  components: {
    loading: Loading,
    filters: Filters,
    "empty-results": EmptyResults,
    "dashboard-tools": DashboardTools,
    "dashboard-charts": DashboardCharts,
    "dashboard-table": DashboardTable,
  },
    mounted() {
      if (!this.$store.state.token) {
        this.$router.replace('/login');
      } else {
        this.loadCompanies();
        bus.$on("updateBetshops", company => {
          this.updateBetshops(company);
        });
      }
    },
  methods: {
    updateBetshops(company) {
      this.loadingMessage = "Updating betshops";
      this.loaderRunning = true;
      let uuid = company.uuid;
      axios.get(`${process.env.VUE_APP_API_ROOT}/companies/getBetshops`, {
        params: {
          uuid: uuid
        },
        headers: {
          Authorization: `Bearer ${this.$store.state.token}`
        }
      }).then(response => {
        let object = JSON.parse(this.$store.state.query);
        let arr = [];
        let responseObj = response.data.data;
        responseObj.forEach(betshop => {
          arr.push(betshop.uuid);
        });
        object["betshopUuid"] = { $in: arr };
        this.$store.commit("updateQuery", JSON.stringify(object, null, 2));
        this.loaderRunning = false;
        this.loadingMessage = "";
      });

    },
    loadQuery() {
      if (this.$store.state.apiEnabled) {
        this.loadingMessage = "Loading data for query";
        this.loaderRunning = true;
        this.loadChartData().then(() => {
          this.loadTableData(1).then(() => {
            this.loadingMessage = "";
            this.loaderRunning = false;
          });
        });
      } else {
        this.$log('[SERVICE] API Disabled in Vuex')
      }
    },
    loadChartData() {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${process.env.VUE_APP_API_ROOT}/devices/getNumbers`,
            JSON.parse(this.$store.state.query),
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.token}`
              }
            }
          )
          .then(response => {
            this.$store.commit("updateChartDevicesData", response.data.data);
            this.$store.commit("updateChartDevicesCount", response.data.count);
            resolve(true);
          })
          .catch(err => {
            this.$log(err);
            reject(err);
          });
      });
    },
    loadTableData(page) {
      return new Promise((resolve, reject) => {
        const requestBody = {
          query: this.$store.state.query,
          sort: null
        };
        axios
          .post(
            `${process.env.VUE_APP_API_ROOT}/devices/get?page=${page}`,
            requestBody,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.token}`
              }
            }
          )
          .then(response => {
            this.$store.commit("updateTableDevicesData", response.data.data);
            bus.$emit("paginationData", response.data.links.pagination);
            this.$store.commit("updatePaginationData", response.data.links.pagination)
            this.$store.commit("updateTablePage", page);
            resolve(true);
          })
          .catch(err => {
            this.$log(err);
            reject(err);
          });
      });
    },
    loadCompanies() {
      if (this.$store.state.apiEnabled) {

        this.loadingMessage = "Fetching companies from DMS";
      this.loaderRunning = true;
      axios
        .get(`${process.env.VUE_APP_API_ROOT}/companies/getCompanies`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.token}`
          }
        })
        .then(response => {
          let data = response.data.data;
          this.$store.commit("updateCompanies", data);
          // this.updateBetshops(data.indexOf(data[0]))
          this.loaderRunning = false;
          this.loadingMessage = "";
        }).catch(err => {
          if (err.response.status === 403) {
            localStorage.removeItem("token");
            this.$store.state.token = "";
            this.$router.replace('/login')
          }
      })
      } else {
        this.$log('[SERVICE] API Disabled in Vuex')
      }
    }
  },
  data() {
    return {
      loaderRunning: false,
      loadingMessage: "",
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.dashboard-body {
  background-color: #f4f6fc;
  width: 100vw;
  height: calc(100vh - 80px);
  .dashboard-container {
    margin-left: 81px;
    /* padding-top: 1px; */
    .table {
      overflow-y: auto;
    }
    .charts {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .data-container {
      width: calc(100% - (81px + 87px + 24px));
      left: calc(81px + 87px);
      overflow-x: hidden;
      margin-right: 24px;
      top: 102px;
      height: calc(100% - 102px);
      position: absolute;
      transition: left 0.3s ease;
      width: 0.3s ease;
    }
    .filtersopened {
      width: calc(100% - (260px + 81px + 48px));
      left: calc(260px + 81px);
      margin-right: 24px;
      margin-left: 24px;
    }
  }
}
</style>
