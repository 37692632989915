<template>
  <div style="width: 100%; font-family: Rubik">
    <vuetable 
      ref="vuetable"
      :per-page="20"
      :api-mode="false" 
      detail-row-component="dashboard-table-details" 
      :data="this.$store.state.tableDevicesData" 
      :css="style.table" 
      :fields="fields"
      track-by="_id"
      @vuetable:cell-dblclicked="onCellClicked"></vuetable>
    <div class="vuetable-pagination ui basic segment grid">
        <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
        <vuetable-pagination  ref="pagination"
                              @vuetable-pagination:change-page="onChangePage"
        ></vuetable-pagination>
      </div>
  </div>
</template>


<script>
import Vue from 'vue'; 
import { bus } from '../../main';
import Vuetable from "vuetable-2/src/components/Vuetable";
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import DashboardTableDetails from "./DashboardTableDetails"

Vue.component('dashboard-table-details', DashboardTableDetails)
export default {
  mounted() {
    const that = this;
    that.$refs.pagination.setPaginationData(this.$store.state.tablePaginationData);
    that.$refs.paginationInfo.setPaginationData(this.$store.state.tablePaginationData);
    bus.$on("paginationData", function(data) {
      that.$refs.pagination.setPaginationData(data);
      that.$refs.paginationInfo.setPaginationData(data);
    })
  },
  props: ['loadPage'],
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
  },
  computed: {
    tablePaginationData () {
      return this.$store.state.tablePaginationData;
    }
  },
  methods: {
    onChangePage (page) {
      if (page === "next") {
        page = (this.$store.state.tablePage === this.$store.state.tablePaginationData.total
      ? this.$store.state.tablePaginationData.total : (this.$store.state.tablePage + 1))
      }
      if (page === "prev") {
        page = (this.$store.state.tablePage === 1 ? 1 : (this.$store.state.tablePage - 1));
      }
      this.loadPage(page);
      this.$store.commit("updateTablePage", page);
    },
    onCellClicked (data) {
      this.$refs.vuetable.toggleDetailRow(data['_id'])
    },
    formatUUID: function(value) {
      return value.toLowerCase()
    },
    deviceType: function(value) {
      return value.charAt().toUpperCase() + value.slice(1);
    },
    onlineDevice: function(value) {
      return value === true ? '<img src="https://i.imgur.com/OizsZ8L.png" />' : '<img src="https://i.imgur.com/HKnB6XG.png" />'
    },
    installedDeviceStatus: function(value) {
      return value === true ? "<span class='text-active'>Installed</span>" :  "<span class='text-inactive'>Not Installed</span>"
    },
    activeDeviceStatus: function(value) {
      return value === true ? "<span class='text-active'>Active</span>" :  "<span class='text-inactive'>Not active</span>"
    },
  },

  data() {
    return {
      fields: [
        {
          name: "uuid",
          title: "UUID",
          titleClass: "center aligned tableTitle",
          dataClass: "center aligned tableData",
          callback: 'formatUUID'
        },
        {
          name: "name",
          title: "Device name",
          sortField: "name",
          titleClass: "center aligned tableTitle",
          dataClass: "center aligned tableData"
        },
        {
          name: "companyName",
          title: "Company",
          sortField: "companyName",
          titleClass: "center aligned tableTitle",
          dataClass: "center aligned tableData"
        },
        {
          name: "betshopName",
          title: "Betshop",
          sortField: "betshopName",
          titleClass: "center aligned tableTitle",
          dataClass: "center aligned tableData"
        },
        {
          name: "type",
          title: "Type",
          callback: "deviceType",
          sortField: "type",
          titleClass: "center aligned tableTitle",
          dataClass: "center aligned tableData"
        },
        {
          name: "sysInfo.software.distro",
          title: "Distro",
          titleClass: "center aligned tableTitle",
          dataClass: "center aligned tableData"
        },
        {
          name: "sysInfo.software.dateInstalled",
          title: "Installation Date",
          titleClass: "center aligned tableTitle",
          dataClass: "center aligned tableData",
        },
        {
          name: "sysInfo.hardware.localIp",
          title: "Local IP",
          titleClass: "center aligned tableTitle",
          dataClass: "center aligned tableData"
        },
        {
          name: "online",
          title: "Online",
          callback: "onlineDevice",
          titleClass: "center aligned tableTitle",
          dataClass: "center aligned tableData",
          sortField: "online"
        },
        {
          name: "isActive",
          title: "Active Status",
          callback: "activeDeviceStatus",
          titleClass: "center aligned tableTitle",
          dataClass: "center aligned tableData",
          sortField: "isActive"
        },
        {
          name: "isInstalled",
          title: "Installation status",
          callback: "installedDeviceStatus",
          titleClass: "center aligned tableTitle",
          dataClass: "center aligned tableData",
          sortField: "isInstalled"
        }
      ],
      style: {
        // table: {
          // tableClass: "tableContainer",
        //   tableBodyClass: "tableBody"
        // }
      }
    };
  }
};
</script>

<style lang="scss">
.vuetable {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
  
}
.ui.table {
  background: transparent !important;
}
.ui.attached.table {
  border: 0 !important;
}
.ui.blue.table {
  border-top: 0 !important;
  border-collapse:separate;
  border-spacing: 0 8px;
}
.vuetable-body tr {
  background-color: #ffffff;
  margin-bottom: 8px !important;
  margin-top: 8px !important;
  border: solid 1px rgba(46, 91, 255, 0.08) !important;
  height: 75px;
}
.tableData {
  vertical-align: middle;
  font-size: 13px;
  font-weight: normal;
  font-family: Rubik;
  color: #2e384d !important;

}
.text-active {
  font-size: 15px;
  font-weight: 500;
  color: #2e5bff;
}
.text-inactive {
  font-size: 15px;
  font-weight: 500;
  color: #b0bac9;
}
tr, td, th {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border-top: 0 !important;
  margin-top: 8px !important;
}
.tableTitle {
  font-size: 12px !important;
  font-weight: 500 !important; 
  color: #bfc5d2 !important;
  font-family: Rubik !important;
  letter-spacing: 1.12px !important;
  text-transform: uppercase !important;
  background: transparent !important;
}
// .tableBody {
//   overflow: auto;
//   margin-top: 32px;
//   margin-bottom: 32px;
// }
// .tableContainer {
//   width: 100%;
//   // height: calc(100vh - (80px + 100px));
//   top: 180px;
//   margin-top: 32px;
//   font-family: Rubik;
//   font-weight: lighter;
//   box-sizing: border-box;
  
//   thead {
//     font-size: 12px;
//     letter-spacing: 1.12px;
//     color: #bfc5d2;
//     font-family: Rubik;
//     font-weight: 400;
//     text-transform: uppercase;
//     margin-bottom: 8px !important;
//   }
//   tbody {
//     overflow-y: auto;
//     tr {
//       background: #ffffff;
//       border: 1px solid rgba(46, 91, 255, 0.08);
//       border-radius: 1px;
//       margin-top: 4px;
//       margin-bottom: 4px;
//       height: 75px;
//       font-size: 0.9em;
//       padding-left: 8px;
//       padding-right: 8px;
//     }
//     td {
//       vertical-align: middle;
      
//     }
//   }
// }
</style>
